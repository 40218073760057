import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import ConversionTable from "../../components/ConversionTable.jsx";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Arbour Assembly - How it works"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`How it works`}</h1>
    <p>{`There are 3 different sizes of 'stand-alone' pergola modules and 2 sizes of 'arbours' to choose from when ordering your preferred option. The pergolas are based upon our standard building blocks of approximately 9ft x 9ft whilst the arbours come in 2 different 'walk-through' lengths to give you a tunnel like structure linking one part of the garden to another.`}</p>
    <p>{`The standard 'building block' system is designed to be extendable in a simple and easy way, proving that you do not need to be a construction engineer to install one!`}</p>
    <p>{`The following sizes are available:`}</p>
    <h4>{`Traditional 'Stand-Alone' Pergola Modules:`}</h4>
    <ol>
      <li parentName="ol">{`MOD 4: Standard - 2.7mtrs x 2.7mtrs (approx: 9ft x 9ft)`}</li>
      <li parentName="ol">{`MOD 5: Standard - 2.7mtrs x 4.86mtrs (approx: 9ft x 16ft)`}</li>
      <li parentName="ol">{`MOD 6: Standard - 2.7mtrs x 7.02mtrs (approx: 9ft x 23ft)`}</li>
    </ol>
    <h4>{`'Walk-Through' Arbours:`}</h4>
    <ol>
      <li parentName="ol">{`MOD 7: Standard - 1.5mtrs x 1.5mtrs (approx: 5ft x 5ft)`}</li>
      <li parentName="ol">{`MOD 8: Standard - 1.5mtrs x 2.5mtrs (approx: 5ft x 8ft)`}</li>
    </ol>
    <p>{`Both the Pergolas and Arbours have a standard height of 2.4 mtrs (7ft 10ins)`}</p>
    <h4>{`Customisation:`}</h4>
    <p>{`If you require different dimensions from the ones detailed above please visit our Pergola Planner page (or you can access it via the homepage) and tell us what you would like to achieve - we'd be delighted to help!`}</p>
    <h4>{`Conversion table:`}</h4>
    <p>{`(for those of you who prefer to work in old fashioned dimensions!)`}</p>
    <ConversionTable mdxType="ConversionTable" />
    <h4>{`Modules Parts List:`}</h4>
    <p>{`Please see below a drawing that shows all the constituent items that are needed in order to successfully build your pergola or arbour. Once you have chosen the size, just tick the option you require on our ordering page and leave the rest to us. All of the necessary components will be automatically calculated and added to your order - making it easier for you to concentrate on just the assembly.`}</p>
    <div className="captioned">
  <img src="/img/arbour-assembly/how-it-works-arbour/arbour-parts.jpg" alt="A schematic of an arbour pergola, marking the positions of the rafters, strusts, posts, beams, and fixings" />
  <span className="text-metadata">Fig 1</span>
    </div>
    <h2>{`Pergola and Arbour Parts List:`}</h2>
    <h4>{`Module 4 - 9ft x 9ft Stand Alone Pergola`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/how-it-works-arbour/mod4-big.jpg" alt="A schematic of Module 4, which is 2.7 x 2.7 x 2.4 metres in size" />
  <span className="text-metadata">Fig 2</span>
    </div>
    <ul>
      <li parentName="ul">{`2 x main beams (decorated both ends) 4 x Posts 4 x Rafters`}</li>
      <li parentName="ul">{`8 x Strengthening struts`}</li>
      <li parentName="ul">{`96 x Wood screws (self tapping recessed 25mm x No.8) for angle brackets`}</li>
      <li parentName="ul">{`16 x Wood screws (self tapping recessed 50mm x No.8) for strengthening struts`}</li>
      <li parentName="ul">{`16 x Metal angle brackets
4 x Coach bolts, nuts & washers sets`}</li>
      <li parentName="ul">{`4 x Metal Fixing post supports (choice of 3 different options - if using the 'bolt-down' system self tapping masonry bolts will be provided)`}</li>
      <li parentName="ul">{`Module 5 - 9ft x 16ft Stand Alone Pergola`}</li>
    </ul>
    <h4>{`Module 5 - 9ft x 16ft Stand Alone Pergola`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/how-it-works-arbour/mod5-big.jpg" alt="A schematic of Module 5, which is 2.7 x 4.86 x 2.4 metres in size" />
  <span className="text-metadata">Fig 3</span>
    </div>
    <ul>
      <li parentName="ul">{`4 x Main beams (decorated one end only)`}</li>
      <li parentName="ul">{`6 x Posts`}</li>
      <li parentName="ul">{`7 x Rafters`}</li>
      <li parentName="ul">{`14 x Strengthening struts`}</li>
      <li parentName="ul">{`168 x Wood screws (self tapping 25mm x No.8) for angle brackets`}</li>
      <li parentName="ul">{`28 x Wood screws (self tapping 50mm x No.8) for strengthening struts`}</li>
      <li parentName="ul">{`28 x Metal angle brackets`}</li>
      <li parentName="ul">{`4 x Coach bolts, nuts & washer sets`}</li>
      <li parentName="ul">{`2 x 'T' connector bracket`}</li>
      <li parentName="ul">{`16 x self tapping non-recessed 25mm x No.6 screws for 'T' bracket`}</li>
      <li parentName="ul">{`6 x Metal Fixing post supports (choice of 3 different options - if using the 'bolt-down' system self tapping masonry bolts will be provided)`}</li>
    </ul>
    <h4>{`Module 6 - 9ft x 23ft Stand Alone Pergola`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/how-it-works-arbour/mod6-big.jpg" alt="A schematic of Module 6, which is 2.7 x 7.02 x 2.4 metres in size" />
  <span className="text-metadata">Fig 3</span>
    </div>
    <ul>
      <li parentName="ul">{`6 x main beams (4 x decorated end & 2 x straight ended middle extension beams)`}</li>
      <li parentName="ul">{`8 x Posts`}</li>
      <li parentName="ul">{`10 x Rafters`}</li>
      <li parentName="ul">{`20 x Strengthening struts`}</li>
      <li parentName="ul">{`240 x Wood screws (self tapping 25mm x No.8) for angle brackets`}</li>
      <li parentName="ul">{`40 x Wood screws (self tapping 50mm x No.8) for strengthening struts`}</li>
      <li parentName="ul">{`40 x Metal angle brackets`}</li>
      <li parentName="ul">{`4 x Coach bolts, nuts & washer sets`}</li>
      <li parentName="ul">{`4 x 'T' connector brackets`}</li>
      <li parentName="ul">{`32 x Self tapping non-recessed 25mm x No.6 screws for ‘T’ bracket`}</li>
      <li parentName="ul">{`8 x Metal Fixing post supports (choice of 3 different options - if using 'bolt-down' system self tapping masonry bolts will be provided)`}</li>
    </ul>
    <h4>{`Module 7 - 1.5mtrs x 1.5mtrs (Small Walk Through Arbour)`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/how-it-works-arbour/mod7-big.jpg" alt="A schematic of Module 7, which is 1.5 x 1.5 x 2.4 metres in size" />
  <span className="text-metadata">Fig 4</span>
    </div>
    <ul>
      <li parentName="ul">{`2 x Main Beams (decorated both ends) at 1.5mtrs each`}</li>
      <li parentName="ul">{`3 x Cross Rafters`}</li>
      <li parentName="ul">{`4 x Posts`}</li>
      <li parentName="ul">{`8 x Strengthening Struts`}</li>
      <li parentName="ul">{`12 x Angle brackets`}</li>
      <li parentName="ul">{`4 x Coach bolts, nuts & washers sets`}</li>
      <li parentName="ul">{`72 x Wood Screw (self tapping recessed 25mm x No.8) for angle brackets`}</li>
      <li parentName="ul">{`16 x Wood Screw (self tapping recessed 50mm x No.8) for strengthening struts`}</li>
      <li parentName="ul">{`4 x Metal Fixing Post Supports (choice of 3 different options - if using 'bolt-down' system self tapping masonry bolts will be provided)`}</li>
    </ul>
    <h4>{`Module 8 - 1.5mtrs x 2.5mtrs (Medium Walk Through Arbour)`}</h4>
    <div className="captioned">
  <img src="/img/arbour-assembly/how-it-works-arbour/mod8-big.jpg" alt="A schematic of Module 8, which is 1.5 x 2.7 x 2.4 metres in size" />
  <span className="text-metadata">Fig 5</span>
    </div>
    <ul>
      <li parentName="ul">{`2 x Main Beams (decorated both ends) at 2.5mtrs each`}</li>
      <li parentName="ul">{`4 x Cross Rafters`}</li>
      <li parentName="ul">{`4 x Posts`}</li>
      <li parentName="ul">{`8 x Strengthening Struts`}</li>
      <li parentName="ul">{`16 x Angle brackets`}</li>
      <li parentName="ul">{`4 x Coach bolts, nuts & washers sets`}</li>
      <li parentName="ul">{`96 x Wood Screw (self tapping recessed 25mm x No.8) for angle brackets`}</li>
      <li parentName="ul">{`16 x Wood Screw (self tapping recessed 50mm x N0.8) for strengthening struts`}</li>
      <li parentName="ul">{`4 x Metal Fixing Post Supports (choice of 3 different options - if using 'bolt-down' system self tapping masonry bolts will be provided)`}</li>
    </ul>
    <p>{`Once you have received your delivery, please carefully check that all items have been included against the packing list supplied and, once done, you’re ready to start the assembly process.`}</p>
    <p><em parentName="p">{`*`}{`NB – Wood, by its very nature, means that you may find it expands or contracts slightly depending on conditions. For example, if you experience difficulty in inserting a 4 inch x 4 inch post into the Fixing Post support - it may be because the wood has expanded a little. If this is the case, just take some coarse sandpaper and sand the bottom outside faces and edges that will be inserted into the support. Not too much though and it’s worth checking about every 30 seconds of sanding as to whether it will fit snuggly into the metal support. If not, continue to gently sand and check often!`}</em></p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Module 1 - assembly guide",
      path: "/downloads/PP1-assembly.pdf"
    }, {
      filename: "Module 2 - assembly guide",
      path: "/downloads/PP2-assembly.pdf"
    }, {
      filename: "Module 3 - assembly guide",
      path: "/downloads/PP3-assembly.pdf"
    }, {
      filename: "Module 4 - assembly guide",
      path: "/downloads/PP4-assembly.pdf"
    }, {
      filename: "Module 5 - assembly guide",
      path: "/downloads/PP5-assembly.pdf"
    }, {
      filename: "Module 6 - assembly guide",
      path: "/downloads/PP6-assembly.pdf"
    }, {
      filename: "Module 7 - assembly guide",
      path: "/downloads/PP7-assembly.pdf"
    }, {
      filename: "Module 8 - assembly guide",
      path: "/downloads/PP8-assembly.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      